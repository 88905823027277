import { i18n } from 'translate/i18n';

import TournamentModalModel from 'pages/CreateTournament/components/TournamentDetailsModal/TournamentModalModel';

const {
  name,
  description,
  rules,
  timeBetweenStages,
  stageTime,
  readyTimeout,
  stagesPerDay,
  minTimeBetweenStages,
  minStageTime,
  minReadyTimeOut,
  minStagesPerDay
} = TournamentModalModel;

export const tournament = {
  banner: {
    alt: 'Banner do jogo'
  },
  playerOneImage: {
    alt: 'Jogador um'
  },
  playerTwoImage: {
    alt: 'Jogador dois'
  },
  text: 'Começa em',
  gameTitle: 'Jogo',
  title02: 'dolares',
  stageOne: 'Fase 1',
  stageTwo: 'Fase 2',
  matches: 'Partidas',
  stagefinals: 'Final',
  platform: 'Platform',
  button: 'Participar',
  rules: 'Regras gerais',
  title03: 'em MatchCash',
  title01: 'Premiação de',
  awardTitle: 'Premiação',
  subTitle: 'Matchbet Tournament {{name}}',
  stageSemifinals: 'Semifinais',
  entranceFee: 'Taxa de entrada',
  buttonText: 'Entrar na partida',
  altIcon: 'Ícone de demonstração',
  currentRoundTitle: 'Partida atual',
  stageQuarterFinals: 'Quartas de final',
  maxPlayersTitle: 'Máximo de jogadores',
  titleStage: 'Chaveamento das partidas',
  minPlayersTitle: 'Mínimos de jogadores',
  registeredPlayers: 'Jogadores registrados',
  description:
    'Participe, ganhe fama mostrando suas habilidades e ganhe dinheiro saindo vencedor!',
  termoTournamentsModal: {
    title: 'Termos e Condições de Uso'
  },
  modalNickname: {
    placeholder: 'Nome',
    title: 'Defina um nome',
    checkBox: `Não me pergunte mais`,
    errorFinancialPassword: 'Senha financeira inválida',
    errorFinancialPasswordNotRegistred: 'Senha financeira não registrada',
    description: 'Colocar o mesmo nome usado na plataforma selecionada.',
    buttons: {
      left: 'Depois',
      right: 'Definir',
      close: 'Fechar'
    },
    messages: {
      playerAlreadyRegistered: 'Já registrado.',
      successfulAcceptBattle: 'Batalha aceite com sucesso.',
      successfulRegistration: 'Registro concluído com sucesso.',
      insufficientWalletBalance: 'Saldo Insuficiente na carteira.',
      registrationFailed: 'O registro falhou, tente novamente mais tarde.',
      unknow_error: 'Erro desconhecido'
    }
  },
  modalMessageBalance: {
    alt: 'Icone da moeda',
    title: 'Seu saldo é insuficiente',
    description: `Compre ${i18n.t(
      'titles.matchcoins'
    )} para entrar no torneio!`,
    buttons: {
      buy: 'Comprar'
    }
  },
  acceptTournament: {
    title: 'Entrar no torneio',
    description:
      'Ao prosseguir você aceita as regras da batalha e termos de uso.',
    errorFinancialPassword: 'Senha financeira inválida',

    gameImage: {
      alt: 'imagem do torneio'
    },
    coinIcon: {
      alt: 'moeda'
    },
    password: {
      placeholder: 'Senha financeira'
    },
    eyeIcon: {
      alt: 'ver senha'
    },
    platforms: 'Na(s) plataforma(s)',
    buttons: {
      cancel: 'Cancelar',
      create: 'Entrar'
    }
  },
  deleteTournament: {
    title: 'Deletar este torneio',
    gameImage: {
      alt: 'Deletar torneio imagem'
    },
    coinIcon: {
      alt: 'Moeda'
    },
    platforms: 'Na(s) plataforma(s)',
    buttons: {
      cancel: 'Cancelar',
      create: 'Deletar'
    }
  },
  notEnoughFunds: 'Você não tem saldo suficiente para entrar nesse torneio.',
  title: 'Torneio MatchCash',
  chatButton: 'Ir para o chat',
  bannerBattle: {
    title: 'Partida atual',
    information: 'Essa batalha já está liberada em seu aplicativo de validador',
    button: 'Regras gerais'
  },
  messenger: {
    title: 'Bate-papo MatchCash',
    badget: {
      label: 'Você tem novas messagens'
    }
  },
  modalNewTournament: {
    modal: {
      title: 'Novo torneio',
      subTitle: 'Escolha o formato do torneio',
      buttonSecondary: 'Cancelar',
      buttonPrimary: 'Avançar',
      typesOfMatch: {
        alone: 'Jogue sozinho',
        team: 'Jogue com um time',
        newTeam: 'Crie um novo time'
      },
      typesOfTournament: {
        withFriends: 'Torneio entre amigos',
        openTournament: 'Torneio aberto'
      }
    }
  },
  editor: {
    field: {
      placeholder: 'Enviar mensagem...'
    }
  },
  selectedCompetitors: {
    title: 'Jogadores selecionados',
    selectPlayers: {
      title: 'Selecione jogadores',
      subTitle: 'Seus amigos selecionados aparecerão aqui.'
    },
    footer: {
      text: {
        first: 'O modelo de torneio escolhido foi o de',
        last: 'participantes.'
      },
      link: 'mudar modelo',
      button: {
        completedTournament: 'Criar torneio',
        completingTournament: 'Selecionado'
      }
    },
    error: {
      tournament_no_nft_matchbet:
        'Você não possui a NFT necessária para criar torneios'
    }
  },
  playerAlreadyRegistered: 'Já registrado.',
  tournamentDetailsModal: {
    title: 'Detalhes do torneio',
    subTitle: 'Preencha os detalhes do torneio para poder continuar',
    formModel: {
      [name.key]: {
        placeholder: 'Nome do torneio',
        messageError: 'Campo obrigatório*'
      },
      [description.key]: {
        placeholder: 'Descrição do torneio',
        messageError: 'Campo obrigatório*'
      },
      [rules.key]: {
        placeholder: 'Regras do torneio',
        messageError: 'Campo obrigatório*'
      },
      [timeBetweenStages.key]: {
        placeholder: 'Tempo entre fases',
        messageError: 'Campo obrigatório*'
      },
      [stageTime.key]: {
        placeholder: 'Tempo de cada fase',
        messageError: 'Campo obrigatório*'
      },
      [readyTimeout.key]: {
        placeholder: 'Tempo de espera, após início do torneio',
        messageError: 'Campo obrigatório*'
      },
      [stagesPerDay.key]: {
        placeholder: 'Fases por dia',
        messageError: 'Campo obrigatório*'
      },
      [minTimeBetweenStages.key]: {
        placeholder: 'Tempo minimo entre estagios',
        messageError: 'O tempo minimo entre estagios é 5 minutos'
      },
      [minStageTime.key]: {
        placeholder: 'Tempo minimo do estagio',
        messageError: 'Tempo minimo do estagio é 5 minutos'
      },
      [minReadyTimeOut.key]: {
        placeholder: 'Tempo minimo de espera',
        messageError: 'Tempo minimo de espera é 3 minutos'
      },
      [minStagesPerDay.key]: {
        placeholder: 'Minimo de estagios por dia',
        messageError: 'O minimo é 2 stages por dia'
      }
    },
    buttonCancel: 'Cancelar',
    buttonCreate: 'Continuar'
  },
  stages: {
    semiFinal: 'Semi Final',
    quarterFinal: 'Quartas de Final'
  },
  finishedMessage: 'Torneio encerrado',
  emptyTournament: {
    title: 'Não há torneios disponíveis',
    paragraph: 'Não há torneios disponíveis no momento'
  }
};
