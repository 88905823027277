import { TextField } from '@mui/material';
import { GeneralButton } from 'components/elements/GeneralButton';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { NftStoreListContext } from 'state/nft/state';
import { servicesApis } from 'service/service.api';
import { useModal } from 'components/modules/Modal';
import { NotificationContext } from 'state/notification/state';
import { UserContext } from 'state/user/state';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import { Actions as nftStoreActions } from 'state/nft/@types/actions';
import { Actions as ActionsUser } from 'state/user/@types/actions';
import { infoNft } from './@types';
import { buyNftSchema } from './buyNftSchema';
import * as S from './BuyNftStyled';

const BuyNft = ({ img, name, value, description }: infoNft) => {
  const translationString = 'components.nft.buyNft';
  const { state, dispatch: nftStoreDispatch } = useContext(NftStoreListContext);
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const { dispatch: dispatchUser } = useContext(UserContext);
  const [load, setLoad] = useState(false);
  const { closeModal } = useModal();

  const buyNft = async (currentPassword: string) => {
    setLoad(true);
    await servicesApis.nftStore
      .postNftStorePurchase(
        state.nft_store_item_selected.entity_id,
        'MATCH_PRESALE',
        currentPassword
      )
      .then(() => {
        setLoad(false);
        notificationDispatch({
          type: NotificationActions.OPEN,
          payload: {
            message: t(`${translationString}.success`)
          }
        });
        nftStoreDispatch({
          type: nftStoreActions.RELOAD
        });
        dispatchUser({
          type: ActionsUser.SET_USER_DATA,
          payload: {
            updateState: true
          }
        });
      })
      .catch((error) => {
        notificationDispatch({
          type: NotificationActions.OPEN,
          payload: {
            message: error.message
          }
        });
      })
      .finally(() => {
        setLoad(false);
        closeModal();
      });
    setLoad(false);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: ''
    },
    onSubmit: ({ currentPassword }) => {
      buyNft(currentPassword);
    },
    validationSchema: buyNftSchema
  });

  return (
    <S.BuyNftContainer>
      <S.Img src={img} alt={t(`${translationString}.alt`)} />
      <S.ContainerDescription>
        <S.Title>
          {name}
          <S.Text>{t(`${translationString}.text`)}</S.Text>
        </S.Title>

        <S.Title>
          <S.Text>{description} </S.Text>
          {value} BET
        </S.Title>

        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
          >
            <TextField
              id="currentPassword"
              type="password"
              label={t(`${translationString}.label`)}
              variant="outlined"
              inputProps={{
                form: {
                  autocomplete: 'off'
                }
              }}
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.currentPassword &&
                Boolean(formik.errors.currentPassword)
              }
              helperText={
                formik.touched.currentPassword && formik.errors.currentPassword
              }
            />
          </S.ContainerInput>
          <GeneralButton
            onClick={() => closeModal()}
            variant="secondary"
            size="medium"
          >
            {t(`${translationString}.secondaryButton`)}
          </GeneralButton>
          <S.ButtonContainer>
            <GeneralButton
              isLoading={load}
              type="submit"
              variant="primary"
              size="medium"
            >
              {t(`${translationString}.button`)}
            </GeneralButton>
          </S.ButtonContainer>
        </S.Form>
      </S.ContainerDescription>
    </S.BuyNftContainer>
  );
};

export default BuyNft;
