export const header = {
  accountButton: {
    userIcon: {
      alt: 'Icone de conta'
    }
  },
  accountButtons: {
    messageIcon: {
      alt: 'Icone de mensagem'
    },
    notificationIcon: {
      alt: 'Icone de notificação'
    }
  },
  indication: {
    copyIcon: {
      alt: 'Icone de copia'
    }
  },
  menuMobile: {
    labels: {
      logout: 'Sair da conta',
      battle: 'Batalhas',
      tournaments: 'Torneios',
      matchBetBanking: 'Banco MatchCash',
      globalRanking: 'Ranking',
      profileSettings: 'Meu Perfil',
      friends: 'Amigos',
      nft: 'Comprar NFTs',
      chat: 'Bate-papo',
      buyMatchcoins: `Comprar {{coin}}`,
      matchBetTournament: 'Torneio MatchCash',
      cart: 'Checkout'
    },
    matchbetIcon: {
      alt: 'Icone do matchbet'
    },
    openCloseIcon: {
      alt: 'Icone de abrir e fechar menu'
    }
  },
  profileMenu: {
    userButton: {
      label: 'Usuários',
      alt: 'Icone do usuario'
    },
    profileSettingsButton: {
      label: 'Configurações de perfil',
      alt: 'Icone de configurações de perfil'
    },
    settingsButton: {
      label: 'Configurações',
      alt: 'Icone de configurações'
    },
    logoutButton: {
      label: 'Sair',
      alt: 'Icone de sair'
    },
    notification: {
      title: 'O que há de novo',
      isNew: 'Novo',
      paragraph:
        'Aqui você irá ver as suas atualizações. Até o momento não há nenhuma.',
      invitation: {
        title: 'Você tem um novo convite',
        paragraph: 'te enviou um novo convite de amizade!',
        accept: 'Aceitar',
        reject: 'Rejeitar'
      },
      aloneBattleRequest: {
        accept: 'Aceitar',
        title: 'Novo desafio!',
        decline: 'Recusar',
        paragraph:
          'Você foi desafiado pelo seu amigo {{apelido}} para jogar {{game}}, na plataforma {{platform}}, valendo {{amount}} {{matchcoins}}.'
      },
      teamBattleRequest: {
        accept: 'Aceitar',
        title: 'Novo desafio!',
        decline: 'Recusar',
        paragraph:
          'Você foi desafiado pelo time {{team_name}} para jogar {{game}}, na plataforma {{platform}}, valendo {{amount}} {{matchcoins}}.'
      },
      aloneBattleAccepted: {
        title: 'Desafio aceito!',
        paragraph:
          'Sua batalha no jogo {{game}} valendo {{amount}} foi aceita pelo usuário {{apelido}}.',
        decline: 'Recusar',
        letsgo: 'Vamos lá!'
      },
      teamBattleAccepted: {
        title: 'Desafio aceito!',
        paragraph:
          'Sua batalha no jogo {{game}} valendo {{amount}} foi aceita pelo time {{team_name}}.',
        decline: 'Recusar',
        letsgo: 'Vamos lá!'
      },
      aloneBattleDeclined: {
        title: 'Desafio recusado!',
        paragraph: 'Sua batalha foi recusada'
      },
      teamBattleDeclined: {
        title: 'Desafio recusado!',
        paragraph: 'Sua batalha foi recusada'
      },
      tournamentStartPhase: {
        title: 'Novo stage!',
        paragraph: 'O torneio{{tournamentName}} , entrou no stage {{phase}}.'
      },
      tournamentInvite: {
        title: 'Novo desafio de torneio!',
        paragraph:
          'Você foi convidado por {{name}} para entrar em um torneio e jogar {{game}}, na plataforma {{platform}}, valendo {{amount}} {{matchcoins}} '
      },
      empty: {
        title: 'Novidades',
        description:
          'Aqui você verá as suas atualizações, que no momento não tem nenhuma.'
      },
      updateListButton: {
        searching: 'Buscando notificações...',
        updateNotifications: 'Atualizar as notificações'
      },
      alt: 'Icone de notificação',
      popup: {
        friendInviteAccepted: 'Agora vocês são amigos!',
        friendInviteAcceptedError:
          'Erro ao aceitar o convite. Tente novamente!',
        friendInviteRejected: 'O convite foi rejeitado!',
        friendInviteRejectedError:
          'Erro ao rejeitar o convite.Tente novamente!',
        battleInviteAcceptedError:
          'Erro ao aceitar a batalha. Tente novamente!',
        battleInviteRejected: 'A batalha foi rejeitada!',
        battleInviteRejectedError:
          'Erro ao rejeitar a batalha. Tente novamente!',
        tournamentInviteAcceptedError:
          'Erro ao entrar no torneio. Tente novamente!'
      }
    }
  }
};
