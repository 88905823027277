export const nft = {
  display: {
    valueLabel: 'Current value',
    coin: 'MTC',
    button: {
      buy: 'BUY',
      view: 'VIEW'
    }
  },
  header: {
    title: 'NFTs Machbet',
    description:
      'NFTs unlock certain functions on the platform, access exclusive items, create tournaments and other functions.'
  },
  listAllNFTs: {
    title: 'Available NFTs',
    yourNft: 'Your NFT',
    days: 'days',
    item: {
      valueLabel: 'Value',
      button: {
        buy: 'BUY',
        view: 'VIEW'
      }
    }
  },
  buyNft: {
    alt: 'NFT image',
    button: 'BUY NFT',
    secondaryButton: 'Close',
    text: 'Current value',
    label: 'Financial password',
    success: 'NFT purchased successfully',
    error: 'NFT purchase failed'
  },
  viewProduct: {
    button: 'BUY',
    text: 'Current value'
  },
  listUserNFTs: {
    title: 'Your NFTs',
    valid_until: 'Valid until',
    item: {
      valueLabel: 'Value',
      button: {
        buy: 'BUY',
        view: 'VIEW'
      }
    }
  }
};
